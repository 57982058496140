<template>
  <div class="d-flex mx-4 gap align-center">
    <number-field
      ref="amountFrom"
      @blur="setValues()"
      @input="$nextTick($refs.amountTo.$refs.value.validate)"
      @keydown.enter="setValues();$emit('close')"
      class="max-width"
      autofocus
      clearable
      nullable
      data-cy="amount-from"
      :label="$t('t.From')"
      :number.sync="numberFrom"
      :rules="maxRules"
    />
    <number-field
      ref="amountTo"
      @blur="setValues()"
      @input="$nextTick($refs.amountFrom.$refs.value.validate)"
      @keydown.enter="$emit('close')"
      class="max-width"
      clearable
      nullable
      data-cy="amount-to"
      :label="$t('t.To')"
      :number.sync="numberTo"
      :rules="minRules"
    />
    <template v-if="isLast">
      <v-btn
        v-if="numberFrom || numberTo"
        @click="$emit('add-cond')"
        icon
      >
        <v-icon>{{$icon('i.Plus')}}</v-icon>
      </v-btn>
      <div
        v-else-if="index"
        style="min-width:36px"
      />
    </template>
    <v-btn
      v-else
      @click="setValues();$emit('delete-cond')"
      icon
    >
      <v-icon>{{$icon('i.Delete')}}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { Condition } from '@/pages/search/controllers/filter-param'

export default {
  components: {
    NumberField: () => import('@/components/number-field.vue')
  },
  computed: {
    maxRules () {
      return [v =>
        this.lodash.isNil(this.numberTo) ||
        this.lodash.isNil(this.numberFrom) ||
        v <= this.numberTo ||
        this.$t('t.MustBeLesserOrEqualTo', { value: this.numberTo })
      ]
    },
    minRules () {
      return [v =>
        this.lodash.isNil(this.numberTo) ||
        this.lodash.isNil(this.numberFrom) ||
        v >= this.numberFrom ||
        this.$t('t.MustBeGreaterOrEqualTo', { value: this.numberFrom })
      ]
    }
  },
  data () {
    return {
      numberFrom: undefined,
      numberTo: undefined
    }
  },
  methods: {
    setValues () {
      this.$emit('set-values', this.numberFrom !== null || this.numberTo !== null ? [this.numberFrom, this.numberTo] : [])
    }
  },
  watch: {
    isMenuOpen: {
      handler (v) {
        if (!v) {
          this.setValues()
        }
      },
      immediate: true
    },
    value: {
      handler (v) {
        [this.numberFrom = null, this.numberTo = null] = v ?? [null, null]
      },
      immediate: true
    }
  },
  props: {
    cond: Condition,
    index: Number,
    isLast: Boolean,
    isMenuOpen: Boolean,
    value: Array
  }
}
</script>

<style lang="stylus" scoped>
.max-width
  width 100%
</style>
